$(window).on("load resize", function () {
  headerHeight();
});

$(document).ready(function () {
  initMobileSubnavEvents();

  $(".first-screen")
    .next(".section")
    .find(".s-title h2")
    .replaceWith(function () {
      return (
        "<h1 class='" + $(this).attr("class") + "'>" + $(this).html() + "</h1>"
      );
    });

  $(".menu-burger").click(function () {
    if ($(this).hasClass("active")) {
      $(this).removeClass("active");
      $(".mobile-nav-box").removeClass("active");
      $("body").css("overflow", "visible");
      $("body").removeClass("scrollbar-hidden");
    } else {
      $(this).addClass("active");
      $(".mobile-nav-box").addClass("active");
      $("body").css("overflow", "hidden");
      $("body").addClass("scrollbar-hidden");
    }
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $(".header").addClass("sticky");
    } else {
      $(".header").removeClass("sticky");
    }
  });

  $(".js-scroll-down").click(function () {
    let fs = $(".first-screen").outerHeight();
    jQuery("html:not(:animated),body:not(:animated)").animate(
      { scrollTop: fs },
      500
    );
    return false;
  });

  $(".search-box__btn").click(function () {
    $(".search-box__field").animate({
      width: "toggle",
      opacity: "toggle",
    });
  });

  $(".detail-slider").magnificPopup({
    type: "image",
    delegate: "a",
    gallery: {
      enabled: true,
      tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
      navigateByImgClick: true,
      preload: [0, 1],
    },
    callbacks: {
      buildControls: function () {
        this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        this.contentContainer.append(
          '<div class="mfp-popup-title">' +
            $(".breadcrumbs ul li:last-child").text() +
            "</div>"
        );
      },
    },
  });

  $(".slider-for").magnificPopup({
    type: "image",
    delegate: "a",
    gallery: {
      enabled: true,
      tCounter: '<span class="mfp-counter">%curr% / %total%</span>',
      navigateByImgClick: true,
      preload: [0, 1],
    },
    callbacks: {
      buildControls: function () {
        this.contentContainer.append(this.arrowLeft.add(this.arrowRight));
        this.contentContainer.append(
          '<div class="mfp-popup-title">' +
            $(".breadcrumbs ul li:last-child").text() +
            "</div>"
        );
      },
    },
  });
});

function headerHeight() {
  const headerHeight = $(".header").outerHeight();
  $(".main").css("padding-top", headerHeight);

  // get height of window and subtract header
  const max_height = $(window).height() - headerHeight;

  const aspect_ratio_style =
    $(window).outerWidth() / 1.9 >= max_height
      ? "aspect-ratio: initial !important;"
      : "";

  // get .main-slider (first-screen)
  $(".first-screen").attr(
    "style",
    // set styles
    "max-height: " + max_height + "px !important;" + " " + aspect_ratio_style
  );

  set_heroImagesHeight_to_firstScreenHeight();
}

function set_heroImagesHeight_to_firstScreenHeight() {
  // get height of main-slider and set it as height of slider__item
  let mainSliderHeight = $(".main-slider").outerHeight();
  $(".main-slider__item").attr(
    "style",
    // set styles
    "height: " + mainSliderHeight + "px !important;"
  );
  if ($(".page-preview").outerHeight() > mainSliderHeight) {
    mainSliderHeight -= 10;
    $(".page-preview").attr(
      "style",
      // set styles
      "height: " + mainSliderHeight + "px !important;"
    );
  }
}

function initMobileSubnavEvents() {
  $("[data-subnav-toggle]").on("click", function () {
    let subnavToShow = $(this).data("subnav-toggle");
    console.log(subnavToShow);
    $(`[data-subnav-item=${subnavToShow}]`).addClass("active");
    $(this).closest(".mobile-nav--container").addClass("inactive");
  });
  $(".mobile-subnav__return").on("click", function () {
    $(this).closest("[data-subnav-item]").removeClass("active");
    $(this)
      .parent()
      .siblings(".mobile-nav--container.inactive")
      .removeClass("inactive");
  });
}
