$(window).on("load", function () {
  $(".main-slider").slick({
    draggable: true,
    arrows: false,
    autoplay: true,
    dots: true,
    fade: true,
    speed: 900,
    infinite: true,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    touchThreshold: 100,
    focusOnSelect: false,
  });

  $(".slider-logo").slick({
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    draggable: false,
    focusOnSelect: false,
  });

  $(".detail-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    fade: true,
    asNavFor: ".detail-slider-nav",
    prevArrow:
      '<div class="slick-prev"><svg viewBox="0 0 44 74" fill="none"><path d="M43.7871 66.0709L14.0871 36.3709L42.5261 7.92794L35.5871 0.991943L0.211109 36.3689L36.8501 73.0069L43.7871 66.0709Z"/></svg></div>',
    nextArrow:
      '<div class="slick-next"><svg viewBox="0 0 44 74" fill="none"><path d="M0.291016 7.92905L29.991 37.6291L1.55202 66.0721L8.49102 73.0081L43.867 37.6311L7.22802 0.993057L0.291016 7.92905Z"/></svg></div>',
  });

  $(".detail-slider-nav").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: ".detail-slider",
    dots: false,
    arrows: false,
    //centerMode: true,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  $(".gallery-slider").slick({
    slidesToScroll: 1,
    slidesToShow: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    prevArrow:
      '<div class="slick-prev slick-arrow"><svg viewBox="0 0 44 74"><path d="M43.7871 66.0709L14.0871 36.3709L42.5261 7.92794L35.5871 0.991943L0.211109 36.3689L36.8501 73.0069L43.7871 66.0709Z"/></svg></div>',
    nextArrow:
      '<div class="slick-next slick-arrow"><svg viewBox="0 0 44 74"><path d="M0.291016 7.92905L29.991 37.6291L1.55202 66.0721L8.49102 73.0081L43.867 37.6311L7.22802 0.993057L0.291016 7.92905Z"/></svg></div>',
  });
  fixGallerySlider();

  $(".slider-for").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    fade: true,
    asNavFor: ".slider-nav",
    prevArrow:
      '<div class="slick-prev"><svg viewBox="0 0 44 74" fill="none"><path d="M43.7871 66.0709L14.0871 36.3709L42.5261 7.92794L35.5871 0.991943L0.211109 36.3689L36.8501 73.0069L43.7871 66.0709Z"/></svg></div>',
    nextArrow:
      '<div class="slick-next"><svg viewBox="0 0 44 74" fill="none"><path d="M0.291016 7.92905L29.991 37.6291L1.55202 66.0721L8.49102 73.0081L43.867 37.6311L7.22802 0.993057L0.291016 7.92905Z"/></svg></div>',
  });

  $(".slider-nav").slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    arrows: false,
    dots: false,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });
});

/* Fix the Height of the Wrapper to the gallery-Slider */
// get height of the gallery-slider and set it as height of slider__item
function fixGallerySlider() {
  let gs_height = $(".gallery-slider").outerHeight();
  $(".gallery-slider")
    .closest(".wrapper")
    .attr("style", "height: " + gs_height + "px !important;");
  $(".gallery-slider .slick-list.draggable").attr("style", "flex-shrink: 2;");
}
